<script setup lang="ts">
  import { dark } from '@clerk/themes'

const navItems = useAppConfig().navigationRootLinks
const colorMode = useColorMode()
</script>

<template>
  <header
    class="sticky top-0 flex h-[--header-height] items-center gap-4 border-b backdrop-blur bg-background/75 px-4 md:px-6 z-50"
  >
    <nav
      class="hidden flex-col gap-6 text-lg font-medium md:flex md:flex-row md:items-center md:gap-5 md:text-sm lg:gap-6"
    >
      <NuxtLink to="/app/explore" class="flex items-center gap-2 text-lg font-semibold md:text-base">
        <Logo />

        <span class="sr-only">Soil Link</span>
      </NuxtLink>
      <!-- <NuxtLink
        v-for="item in navItems"
        :key="item.href"
        :to="item.href"
        class="text-muted-foreground transition-colors hover:text-foreground"
        active-class="text-foreground font-semibold"
        exact-active-class="text-primary font-semibold"
      >
        {{ item.name }}
      </NuxtLink> -->
      <HeaderNavMenu />
    </nav>
    <Sheet>
      <SheetTrigger as-child>
        <Button variant="outline" size="icon" class="shrink-0 md:hidden">
          <Icon name="soilicon:menu-01" class="h-5 w-5" />
          <span class="sr-only">Toggle navigation menu</span>
        </Button>
      </SheetTrigger>
      <SheetContent side="left">
        <nav class="grid gap-6 text-lg font-medium">
          <NuxtLink to="/" class="flex items-center gap-2 text-lg font-semibold">
            <Logo :with-label="false" class="w-8 h-8" />
            <span class="sr-only">Soil Link</span>
          </NuxtLink>
          <NuxtLink
            v-for="item in navItems" :key="item.href" :to="item.href"
            class="text-muted-foreground hover:text-foreground"
          >
            {{ item.name }}
          </NuxtLink>
        </nav>
      </SheetContent>
    </Sheet>

    <div class="flex w-full items-center gap-4 md:ml-auto md:gap-2 lg:gap-4">
      <div class="ml-auto flex-1 sm:flex-initial">
        <PostHogFeatureFlag name="app_header_search">
          <!-- <form>
            <div class="relative">
              <Icon
                name="soilicon:search-md"
                class="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground"
              />
              <Input
                type="search"
                placeholder="Search..."
                class="pl-8 sm:w-[300px] md:w-[200px] lg:w-[300px]"
              />
            </div>
          </form> -->
        </PostHogFeatureFlag>
      </div>
      <ClerkLoaded>
        <SignedIn>
          <PostHogFeatureFlag name="terra_basic_header_link">
            <NuxtLink to="/app/terra" class="text-muted-foreground transition-colors hover:text-foreground">
              <Button variant="ghost" size="icon">
                <Icon name="soilicon:stars-02" class="text-primary h-6 w-6" />
              </Button>
            </NuxtLink>
          </PostHogFeatureFlag>
          <AdminHeaderDropdownMenu />
          <OrganizationSwitcher
            :appearance="{
              baseTheme: colorMode.value === 'dark' ? dark : undefined,
              elements: {
                organizationPreviewAvatarBox: 'size-8',
                notificationBadge: 'size-4',
                // organizationName: 'text-lg',
                // organizationPreviewMainIdentifier: 'text-sm max-w-24 truncate',
              },
            }" :hide-personal="true" :hide-slug="true" after-create-organization-url="/account/org-created"
            after-leave-organization-url="/account/org-left" :skip-invitation-screen="false"
            create-organization-url="/account/organization/create"
          />

          <UserButton
            :appearance="{
              baseTheme: colorMode.value === 'dark' ? dark : undefined,
              elements: {
                userButtonAvatarBox: 'size-10',
              },
            }" :show-name="false"
          />
        </SignedIn>
        <SignedOut>
          <!-- <SignInButton mode="modal" /> -->
          <SignInButton>
            <Button variant="link">
              Sign In
            </Button>
          </SignInbutton>
          <SignUpButton>
            <Button>
              Create Account
            </Button>
          </SignUpButton>
        </SignedOut>
      </ClerkLoaded>
      <ClerkLoading>
        <Skeleton class="h-10 w-10 rounded-full" />
      </ClerkLoading>
    </div>
    <!-- <HeaderCriticalNotification /> -->
  </header>
</template>
