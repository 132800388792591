<script setup lang="ts">
  import { navigationMenuTriggerStyle } from '@/modules/ui/components/navigation-menu'

  const manageMenuItems: { title: string, href: string, description: string }[] = [
  {
    title: 'My Projects',
    href: '/app/manage/projects',
    description:
        'Your organization\'s projects and material needs.',
  },
  //   {
  //     title: 'Imports',
  //     href: '/app/manage/imports',
  //     description:
  //         'Your material import / supply needs.',
  //   },
  //   {
  //     title: 'Exports',
  //     href: '/app/manage/exports',
  //     description:
  //         'Your material export / removal needs.',
  //   },
  //   {
  //     title: 'Inventory',
  //     href: '/app/manage/inventory',
  //     description: 'Manage your material inventory across projects.',
  //   },
  //   {
  //     title: 'CO2e Reports',
  //     href: '/app/manage/co2e',
  //     description:
  //         'Real-time CO2e reports.',
  //   },
  //   {
  //     title: 'AI Insights',
  //     href: '/app/manage/insights',
  //     description:
  //         'AI-powered insights and recommendations.',
  //   },
]

const moreMenuItems: { title: string, href: string, description: string }[] = [
  // {
  //   title: 'Messages',
  //   href: '/app/messages',
  //   description:
  //       'Your messages from suppliers and other users.',
  // },
    {
      title: 'Account Settings',
      href: '/app/settings/account/general',
      description:
        'Account settings and preferences.',
    }
]

// const intelligenceMenuItems: { title: string, href: string, description: string }[] = [
//   {
//     title: 'TerraAI',
//     href: '/app/terra',
//     description:
//         'Chat to Terra, our AI construction material expert.',
//   },
//     {
//       title: 'Account Settings',
//       href: '/app/settings/account/general',
//       description:
//         'Account settings and preferences.',
//     }
// ]

const superadminMenuItems: { title: string, href: string, description: string }[] = [
    {
      title: 'Imports',
      href: '/app/manage/imports',
      description:
        'Your material import / supply needs.',
    },
    {
      title: 'Exports',
      href: '/app/manage/exports',
      description:
        'Your material export / removal needs.',
    },
    {
      title: 'Inventory',
      href: '/app/manage/inventory',
      description: 'Manage your material inventory across projects.',
    },
    {
      title: 'CO2e Reports',
      href: '/app/manage/co2e',
      description:
        'Real-time CO2e reports.',
    },
    {
      title: 'AI Insights',
      href: '/app/manage/insights',
      description:
        'AI-powered insights and recommendations.',
    },
]

const developerMenuItems: { title: string, href: string, description: string }[] = [
    {
      title: 'Dev Tools',
      href: '/dev',
      description:
        'Developer tools and resources.',
    },
    {
      title: 'API Docs',
      href: '/',
      description:
        'API documentation and resources.',
    },
    {
      title: 'User Debug Tools',
      href: '/dev/user',
      description: 'Developer tools for debugging user data.',
    }

]
</script>

<template>
  <NavigationMenu>
    <NavigationMenuList>
      <NavigationMenuItem>
        <NavigationMenuTrigger>Find</NavigationMenuTrigger>
        <NavigationMenuContent>
          <ul class="grid gap-3 p-6 md:w-[400px] lg:w-[500px] lg:grid-cols-[minmax(0,.75fr)_minmax(0,1fr)]">
            <li class="row-span-3">
              <NavigationMenuLink as-child:class="navigationMenuTriggerStyle()">
                <NuxtLink

                  class="flex h-full w-full select-none flex-col justify-end rounded-md bg-gradient-to-b from-muted/50 to-muted p-6 no-underline outline-none focus:shadow-md border-2 border-transparent hover:border-primary"
                  href="/app/explore/"
                >
                  <Icon name="soilicon:map-01" class="size-8 text-primary" />
                  <div class="mb-2 mt-4 text-lg font-medium">
                    Map
                  </div>
                  <p class="text-sm leading-tight text-muted-foreground">
                    Explore the Soil Link map to find suppliers and materials.
                  </p>
                </NuxtLink>
              </NavigationMenuLink>
            </li>

            <!-- <li>
              <NavigationMenuLink as-child class="data-[active]:bg-accent/50 data-[state=open]:bg-accent/50">
                <NuxtLink
                  to="/app/manage/projects"
                  class="block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground"
                >
                  <div class="text-sm font-medium leading-none">
                    My Projects
                  </div>
                  <p class="line-clamp-2 text-sm leading-snug text-muted-foreground">
                    View your organization's projects and manage material needs.
                  </p>
                </NuxtLink>
              </NavigationMenuLink>
            </li> -->
            <li>
              <NavigationMenuLink as-child>
                <NuxtLink
                  to="/app/directory/rfq"
                  class="block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground border-2 border-transparent hover:border-primary"
                >
                  <div class="text-sm font-medium leading-none">
                    Open RFQs
                  </div>
                  <p class="line-clamp-2 text-sm leading-snug text-muted-foreground">
                    Find opportunities on the open marketplace.
                  </p>
                </NuxtLink>
              </NavigationMenuLink>
            </li>
            <li>
              <NavigationMenuLink as-child>
                <NuxtLink
                  to="/companies"
                  class="block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground border-2 border-transparent hover:border-primary"
                >
                  <div class="text-sm font-medium leading-none">
                    Suppliers
                  </div>
                  <p class="line-clamp-2 text-sm leading-snug text-muted-foreground">
                    Find companies to partner with for your projects.
                  </p>
                </NuxtLink>
              </NavigationMenuLink>
            </li>

            <li>
              <NavigationMenuLink as-child class="data-[active]:bg-accent/50 data-[state=open]:bg-accent/50">
                <NuxtLink
                  to="/app/directory/global-projects"
                  class="block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground border-2 border-transparent hover:border-primary"
                >
                  <div class="text-sm font-medium leading-none">
                    Construction Projects
                  </div>
                  <p class="line-clamp-2 text-sm leading-snug text-muted-foreground">
                    Find opportunities by exploring nationwide construction projects.
                  </p>
                </NuxtLink>
              </NavigationMenuLink>
            </li>
          </ul>
        </NavigationMenuContent>
      </NavigationMenuItem>
      <NavigationMenuItem>
        <NavigationMenuTrigger>Manage</NavigationMenuTrigger>
        <NavigationMenuContent>
          <ul class="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px] ">
            <li v-for="item in manageMenuItems" :key="item.title">
              <NavigationMenuLink as-child>
                <NuxtLink
                  :to="item.href"
                  class="block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground"
                >
                  <div class="text-sm font-medium leading-none">
                    {{ item.title }}
                  </div>
                  <p class="line-clamp-2 text-sm leading-snug text-muted-foreground">
                    {{ item.description }}
                  </p>
                </NuxtLink>
              </NavigationMenuLink>
            </li>
          </ul>
        </NavigationMenuContent>
      </NavigationMenuItem>
      <!-- <NavigationMenuItem>
        <NavigationMenuTrigger>Intelligence</NavigationMenuTrigger>
        <NavigationMenuContent>
          <ul class="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px] ">
            <li v-for="item in intelligenceMenuItems" :key="item.title">
              <NavigationMenuLink as-child>
                <NuxtLink
                  :to="item.href"
                  class="block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground"
                >
                  <div class="text-sm font-medium leading-none">
                    {{ item.title }}
                  </div>
                  <p class="line-clamp-2 text-sm leading-snug text-muted-foreground">
                    {{ item.description }}
                  </p>
                </NuxtLink>
              </NavigationMenuLink>
            </li>
          </ul>
        </NavigationMenuContent>
      </NavigationMenuItem> -->
      <!-- <NavigationMenuItem>
        <NavigationMenuTrigger>More</NavigationMenuTrigger>
        <NavigationMenuContent>
          <ul class="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px] ">
            <li v-for="item in moreMenuItems" :key="item.title">
              <NavigationMenuLink as-child>
                <NuxtLink
                  :to="item.href"
                  class="block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground"
                >
                  <div class="text-sm font-medium leading-none">
                    {{ item.title }}
                  </div>
                  <p class="line-clamp-2 text-sm leading-snug text-muted-foreground">
                    {{ item.description }}
                  </p>
                </NuxtLink>
              </NavigationMenuLink>
            </li>
          </ul>
        </NavigationMenuContent>
      </NavigationMenuItem> -->
      <NavigationMenuItem v-if="useIsPlatformAdmin()" class="opacity-20 ml-4 -mr-4">
        <NavigationMenuTrigger><Icon name="soilicon:dots-grid" class="size-5" /></NavigationMenuTrigger>
        <NavigationMenuContent>
          <ul class="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px] ">
            <li v-for="item in superadminMenuItems" :key="item.title">
              <NavigationMenuLink as-child>
                <NuxtLink
                  :to="item.href"
                  class="block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground"
                >
                  <div class="text-sm font-medium leading-none">
                    {{ item.title }}
                  </div>
                  <p class="line-clamp-2 text-sm leading-snug text-muted-foreground">
                    {{ item.description }}
                  </p>
                </NuxtLink>
              </NavigationMenuLink>
            </li>
          </ul>
        </NavigationMenuContent>
      </NavigationMenuItem>
      <NavigationMenuItem v-if="useUserIsDeveloper()" class="opacity-20">
        <NavigationMenuTrigger><Icon name="soilicon:code-square-02" class="size-5 opacity-50" /></NavigationMenuTrigger>
        <NavigationMenuContent class="opacity-50">
          <ul class="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-1 lg:w-[600px] ">
            <li v-for="item in developerMenuItems" :key="item.title">
              <NavigationMenuLink as-child>
                <NuxtLink
                  :to="item.href"
                  class="block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground"
                >
                  <div class="text-sm font-medium leading-none">
                    {{ item.title }}
                  </div>
                  <p class="line-clamp-2 text-sm leading-snug text-muted-foreground">
                    {{ item.description }}
                  </p>
                </NuxtLink>
              </NavigationMenuLink>
            </li>
          </ul>
        </NavigationMenuContent>
      </NavigationMenuItem>
      <!-- <NavigationMenuItem>
        <NuxtLink to="/app/messages">
          <NavigationMenuLink :class="navigationMenuTriggerStyle()">
            Messages
          </NavigationMenuLink>
        </Nuxtlink>
      </NavigationMenuItem> -->
      <!-- <NavigationMenuItem>
        <NuxtLink to="/app/settings/account/general">
          <NavigationMenuLink :class="navigationMenuTriggerStyle()">
            Settings
          </NavigationMenuLink>
        </Nuxtlink>
      </NavigationMenuItem> -->
    </NavigationMenuList>
  </NavigationMenu>
</template>
